/* Chat Container Styling */
.chat-container {
    background-color: #f8f9fa;
    border-radius: 15px;
    padding: 30px;
    min-width: 300px;
    margin: 40px auto;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.chat-container:hover {
    transform: scale(1.02);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

/* Chat Icon Button Styling */
.icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
}

.chat-icon {
    width: 60px;
    height: 60px;
    transition: transform 0.2s ease;
}

.icon-button:hover .chat-icon {
    transform: scale(1.1);
}

/* Close Button Styling */
.close-button {
    display: block;
    margin-left: auto;
    padding: 10px 20px;
    background-color: maroon;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.close-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Message Styling */
.message-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.message-input {
    width: 70%;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    font-size: 1.1rem;
    transition: border-color 0.3s ease;
}

.message-input:focus {
    border-color: #007bff;
}

/* Send Button Styling */
.send-button {
    padding: 15px 25px;
    font-size: 1.2rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-left: 10px;
}

.send-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 350px;
}

.modal-input {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.modal-cancel-button {
    background-color: #dc3545;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
