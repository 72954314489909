/* MessageBox Container */
.message-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 15px;
    max-width: 700px;
    margin: 20px auto;
}

/* Input Field */
.message-input {
    width: 75%;
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 12px;
    border: 2px solid #ccc;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    background-color: #fafafa;
    color: #333;
}

.message-input:focus {
    border-color: #007bff;
    box-shadow: 0 6px 15px rgba(0, 123, 255, 0.2);
}

/* Send Button */
.send-button {
    padding: 15px 25px;
    font-size: 1.2rem;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.send-button:hover {
    background-color: #218838;
    transform: scale(1.05);
}

/* Modal Styling */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 350px;
}

.modal-input {
    width: 100%;
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    outline: none;
    margin-bottom: 20px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
}

.modal-submit-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

.modal-cancel-button {
    background-color: #dc3545;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
