/* Adjust layout for desktop */
.dashboard-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 40px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 40px;
    background-color: #fafafa;
}

/* Beach Information on the left */
.beach-info-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    transition: transform 0.3s ease-in-out;
}

.beach-info-container:hover {
    transform: scale(1.02);
}

/* Header styling */
.beach-header {
    margin-bottom: 30px;
    text-align: center;
}

.beach-name {
    font-size: 2.5rem;
    color: #333;
    font-weight: 600;
    letter-spacing: 2px;
}

.beach-location, .beach-safety {
    font-size: 1.5rem;
    color: #666;
}

/* Safe/unsafe styling */
.safe {
    color: #4CAF50;
}

.unsafe {
    color: #FF4C4C;
}

.partially-safe {
    color: #FFC107;
}

/* Component containers */
.weather-container, .amenities-container, .chat-container {
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    transition: transform 0.3s ease-in-out;
}

.weather-container:hover, .amenities-container:hover, .chat-container:hover {
    transform: scale(1.05);
}

.chat-container {
    margin-top: 50px;
}

/* Typography */
h3 {
    font-size: 2rem;
    color: #444;
    margin-bottom: 0;
}

/*Media query */
@media (max-width:1150px) {
    .dashboard-container{
        flex-direction: column;
    }
}



