#mapCanvas {
  height: 100vh;
  width: 100vw;
  border-radius: 1rem;
}

#mapIcon {
  height: 2vh;
  width: 2vh;
  border-radius: 1rem;
}

.custom-cluster-icon, .cluster-icon {
  display: inline-block;
  background-color: midnightblue;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  border: 2px solid black;
  transition: transform 0.3s ease;
}

.custom-cluster-icon:hover, .cluster-icon:hover {
  transform: scale(1.5);
  background-color: royalblue;
}
