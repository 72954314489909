/* General Styles */



.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: midnightblue;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;
    /* Always on top */
}

.nav-container{
    display: none;
}

.navbar-brand {
    font-size: 24px;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 7px;
    transition:0.3s ease-in-out;
    -webkit-transition:0.3s ease-in-out;
    -moz-transition:0.3s ease-in-out;
    -ms-transition:0.3s ease-in-out;
    -o-transition:0.3s ease-in-out;
}

.navbar-brand img {
    width: 40px;
}

.navbar-brand:hover,
.navbar-brand img:hover {
    transform: scale(1.2);
    color: gold;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}


.navbar-nav {
    display: flex;
    gap: 30px;
    list-style: none;
    justify-content: center;
    align-items: center;
}

.nav-link {
    margin-top: 8.5px;
    color: white;
    text-decoration: none;
    transition: transform 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
    transform: scale(1.3);
    color: gold;
}

.nav-btn {
    width: 120px;
    height: 50px;
    background-color: royalblue;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    -webkit-transition: background-color 0.3s ease, transform 0.3s ease;
    -moz-transition: background-color 0.3s ease, transform 0.3s ease;
    -ms-transition: background-color 0.3s ease, transform 0.3s ease;
    -o-transition: background-color 0.3s ease, transform 0.3s ease;
}

.nav-btn:hover {
    background-color: gold;
    color: midnightblue;
    transform: scale(1.3);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    -webkit-transform: scale(1.3);
}

.nav-btn:active {
    transform: scale(0.98);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Mobile Menu Icon */
.mobile-menu-icon {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

/* Hero Section */
header.masthead {
    position: relative;
    height: 100vh;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Mobile Menu Overlay */
.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 200;
    /* Ensures mobile menu overlays everything */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mobile-nav-links {
    list-style: none;
    text-align: center;
}

.mobile-nav-item {
    margin: 20px 0;
}

.mobile-nav-item a {
    color: white;
    font-size: 24px;
    text-decoration: none;
    transition: color 0.3s ease;
}

.mobile-nav-item a:hover {
    color: gold;


}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 28px;
    height: 20px;
    cursor: pointer;
    justify-self: flex-end;
}

.hamburger span {
    background-color: white;
    height: 3px;
    width: 100%;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.hamburger span:nth-child(1),
span:nth-child(3) {
    width: 16px;
}

.hamburger span:nth-child(3) {
    align-self: flex-end;
}

.mobile-menu-icon {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 30%; /* This is needed to place it precisely */
    right: 5%; /* Ensures the button is 5% from the right side */
}

/* Media Queries */
@media (max-width: 1150px) {
    .container{
        display: none;
    }
    .nav-container{
        display: flex;
    }
    .navbar-brand {
        margin-right: 20px;
        align-self: flex-start;
    }
    
    .navbar-nav {
        display: none;
        background-color: midnightblue;
        justify-content: space-between;
    }

    .mobile-menu-icon {
        display: flex;
        justify-self: flex-end;
        color: white;
        transition: ease-in-out 0.3s;
        right: 5%; /* Ensures the button stays 5% from the right on mobile */
        position: absolute; /* Needed for proper placement */
    }

    .mobile-menu-icon:hover {
        color: gold;
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
        -moz-transform: scale(1.5);
        -ms-transform: scale(1.5);
        -o-transform: scale(1.5);
    }

    .mobile-menu-icon.active:hover{
        background-color: red;
    }

}

