/* Luxurious Styling for WriteReport Component */
.report-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #1a1a1d; /* Dark elegant background */
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
  color: #f0e5d8; /* Subtle luxury text color */
  font-family: 'Lora', serif; /* Luxurious serif font */
}

.report-form-container {
  padding: 30px;
  background-color: #2c2c34; /* Slightly lighter background for form */
  border-radius: 15px;
}

.report-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.report-heading {
  font-size: 2rem;
  color: #e8c547; /* Golden yellow color */
  text-align: center;
  margin-bottom: 20px;
}

.report-label {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  color: #e8c547; /* Golden labels */
}

.report-input,
.report-select,
.report-textarea {
  padding: 15px;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid #e8c547; /* Golden border */
  background-color: #f8f4f1; /* Off-white background */
  color: #1a1a1d; /* Dark text for contrast */
  margin-top: 10px;
}

.report-select,
.report-input {
  height: 50px;
}

.report-textarea {
  min-height: 120px;
}

.report-button {
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #1a1a1d; /* Dark text */
  background-color: #e8c547; /* Golden button background */
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.4s ease;
  font-weight: bold;
}

.report-button:hover {
  background-color: #b89535; /* Darker golden hover effect */
}

@media (max-width: 768px) {
  .report-container {
      padding: 20px;
  }

  .report-heading {
      font-size: 1.5rem;
  }

  .report-button {
      padding: 12px 20px;
      font-size: 1rem;
  }
}
