/* AdminPage.css */

.admin-container {
  margin-top: 22px;
  display: flex;
  flex-direction: column;
    padding: 150px;
    text-align: center;

  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background-color: #007BFF;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: gold;
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
}
  