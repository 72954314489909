.search-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.search-box {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 600px;
    padding: 10px 20px;
    border: 1px solid #dfe1e5;
    border-radius: 24px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    transition: box-shadow 0.3s ease;
}

.search-box:hover {
    box-shadow: 0 2px 8px rgba(32, 33, 36, 0.3);
}

.search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    outline: none;
}

.search-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0 10px;
}

.search-button i {
    font-size: 20px;
    color: #5f6368;
}

.search-input:focus {
    outline: none;
    border: none;
}