/* Grid Container */
.grid-container {
    background: #f4f4f9; /* A soft background */
    font-family: 'Rubik', sans-serif;
    width:100%; /* Full width for proper scaling */
    padding: 20px; /* Padding around the container for breathing space */
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

@supports (display: grid) {
    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90vw, 1fr)); /* Responsive grid */
        gap: 20px; /* Spacing between grid items */
    }

    .item {
        background: linear-gradient(145deg, #e4e4e7, #cfcfd4); /* Slight gradient background */
        padding: 2em 1em;
        font-size: 1em;
        font-weight: 700;
        border-radius: 15px;
        box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Luxurious shadow */
        text-align: center;
        cursor: poi;
        transition: transform 0.3s, box-shadow 0.3s;
        min-height: 300px;

    }

    .item:hover {
        transform: translateY(-10px); /* Lift effect on hover */
        box-shadow: 0 25px 35px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
    }
}

/* Items Container */
.items {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    overflow-x: auto; /* Horizontal scroll for better navigation */
    white-space: nowrap;
    padding-bottom: 10px;
    scrollbar-width: none; /* Hide default scrollbar */
}

.items::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Webkit browsers */
}

/* Individual Item Styling */
.item {
    display: inline-block;
    background: linear-gradient(145deg, #ffffff, #f0f0f0); /* Light gradient */
    border-radius: 15px;
    padding: 20px;
    width: 500px;
    height: 240px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Softer shadow */
    text-align: center;
    cursor: grabbing;
    transition: transform 0.3s, box-shadow 0.3s ease;
}

.item:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.item p {
    margin: 15px 0;
    font-size: 1.2em;
    color: #444;
    font-weight: bold;
}

/* Button Styling */
.item button {
    background-color: #004d99; /* Deep blue button */
    color: white;
    padding: 12px 25px;
    border-radius: 8px;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.item img {
    width: 90px;
    /*Smallerimagesizeforacleanerlook*/height: auto;
    margin-bottom: 10px;
    border-radius: 100px;
    /*Circularimageforaluxurioustouch*/border: 2px solid midnightblue;
    /*Goldborderaroundimage*/-webkit-border-radius: 50%;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    -webkit-border-radius: 100px;
}

.item button:hover {
    background-color: goldenrod;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.no-highlight {
    user-select: none; /* Prevent text selection */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr)); /* Adjust for tablets */
    }
}

@media screen and (max-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr)); /* Adjust for smaller screens */
    }

}

@media screen and (max-width: 480px) {
    .grid-container {
        grid-template-columns: repeat(auto-fill, minmax(100vw, 1fr)); /* Adjust for mobile */
    }


    .item button {
        font-size: 0.9rem; /* Smaller font size for buttons on mobile */
        padding: 10px 20px;
    }
}
