* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.c-wrapper {
    margin: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.c-container {
    width: 28vmin;
    height: 28vmin;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Vertically center the items */
    align-items: center;     /* Horizontally center the items */
    padding: 1em 0;
    position: relative;
    font-size: 16px;
    border-radius: 0.5em;
}

i {
    color: midnightblue;
    font-size: 2.5em;
    text-align: center;
}

.c-container .num {
    color: midnightblue;
    display: grid;
    place-items: center;     /* Centering text in the grid */
    font-weight: 600;
    font-size: 3em;
    text-align: center;
}

.c-container .c-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;     /* Centering items */
    text-align: center;
    color: midnightblue;
    font-size: 1em;
    padding: 0.7em 0;
    font-weight: bolder;
}
