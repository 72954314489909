/* AddBeach.css */

.form-container {
   display: flex;
    padding: 20px;
    margin: auto auto;
    max-width: 600px;
    text-align: left;
    color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: white;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: 500;
    color: #555;
  }
  
  .section-title .input {
    width: 100px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox {
    margin-right: 8px;
    color: goldenrod;
  }
  
  .select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007BFF;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  