/* AdminLoginPage.css */

.admin-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: whitesmoke;
  
}

.form-container {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: royalblue;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}


.form-container {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: royalblue;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  text-align: center;
}


.label {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: white;
  font-weight: bold;
}

.input {
  width: 250px;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.input:focus {
  border-color: #4e4376;
}

.button {
  width: 120px;
  height: 60px;
  background-color: midnightblue;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  -webkit-transition: background-color 0.3s ease, transform 0.3s ease;
  -moz-transition: background-color 0.3s ease, transform 0.3s ease;
  -ms-transition: background-color 0.3s ease, transform 0.3s ease;
  -o-transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: gold;
  color: midnightblue;
  transform: scale(1.3);
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.error-message {
  color: #ff4d4d;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #ffe6e6;
  padding: 10px;
  border-radius: 5px;
}

.admin-info {
  margin-top: 20px;
}

.admin-info h3 {
  font-size: 1.5rem;
  color: #4e4376;
}

.admin-info p {
  font-size: 1.2rem;
  color: #333;
}
