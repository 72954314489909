.form-container{

    padding:300px;
    margin-top: 10px;
    max-width: 600px;
    text-align: left;
    background-color: midnightblue;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-width: 450px;
  }
  
  #ContainerContainer{
    margin-top: 56px;
    display: flex;
    justify-content: center;
  }


  
  .input,
  .select {
    width: 150px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .checkboxLabel {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .checkbox {
    margin-right: 8px;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #007BFF;
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  form .label{
    color: white;
  }

  .errorMessage {
    color: red;
    font-size: 1.2rem;
  }
  