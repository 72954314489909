/* Video container */



/* HeroSection.css */

/* The container for the video */
.video-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;  /* Ensures the video stays behind the content */
    overflow: hidden;
    background:black;
}

/* Video settings to ensure it covers the background */
.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Ensures the video covers the area without distorting */
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;

}

/* The rest of the hero section content */
.masthead {
    position: relative;
    height: 100vh; /* Full height of the viewport */
    min-height: 500px;  /* Minimum height for smaller screens */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white; /* Text color for visibility */
    z-index: 1; /* Make sure text appears above the video */
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
}

/* Ensuring the content stays in the center */
.masthead .container {
    z-index: 2; /* Ensures content is on top of the video */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.masthead h1 {
    font-size: 3.5rem;
    font-weight: bold;
    margin: 15px;
}

.masthead p {
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.816);
    margin: 15px;
}

.masthead .btn-primary {
    width: 120px;
    height: 50px;
    background-color: midnightblue;
    border-color: blue;
    color: #ffffff;/*Textcolor*/
    font-size: 16px;/*Fontsizeforthebutton*/
    font-weight: bold;/*Makethetextbold*/
    border: none;/*Removeborder*/
    border-radius: 8px;/*Roundedcorners*/
    cursor: pointer;/*Changecursortopointeronhover*/
    transition: background-color 0.3s ease, transform 0.3s ease;/*Smoothtransitionforbackgroundandtransform*/
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); /* Adding shadow for depth */
    -webkit-transition: background-color 0.3s ease, transform 0.3s ease;
    -moz-transition: background-color 0.3s ease, transform 0.3s ease;
    -ms-transition: background-color 0.3s ease, transform 0.3s ease;
    -o-transition: background-color 0.3s ease, transform 0.3s ease;
}

.masthead .btn-primary:hover {
    background-color: goldenrod;
    color: whitesmoke;
    transform: scale(1.3);
    /*Slightlyincreasethesizeonhover*/box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    /*Addabiggershadowonhover*/-webkit-transform: scale(1.5);
    -moz-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    -webkit-transform: scale(1.3);
}


.btn-primary:active {
    transform: scale(0.98); /* Scale down the button when clicked */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Reduce shadow on click */
}
