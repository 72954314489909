



/* Centering the container */
.education-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Chat container styling */
.educational-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
  background-color: var(--primary-color);
  border-radius: 10px;
  max-width: 900px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin: 40px;
}

.educational-content:hover {
  transform: translateY(-5px);
}

/* Section headers */
.section {
  margin-bottom: 50px;
}

.section h3 {
  color: midnightblue;
}

  .educational-content h1{
    color: midnightblue;
  }

.section h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2.2rem;
  text-transform: uppercase;
  color: var(--secondary-color);
  border-bottom: 2px solid var(--highlight-color);
  padding-bottom: 10px;
  letter-spacing: 1.5px;
}

/* Section text */
p, ul {
  font-size: 1.2rem;
  line-height: 1.7;
  color: var(--text-color);
  margin: 10px 0;
}

/* List styling */
ul li {
  margin-bottom: 10px;
  list-style: none;
}


/* Smooth transitions */
* {
  transition: all 0.3s ease;
}


